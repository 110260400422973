import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import HomePage from './Pages/HomePage';
import Careers from "./Components/Careers"
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<HomePage />} />
        {/* <Route exact path='/' element={<Header/>}/>  */}
        <Route exact path='/careers' element={<Careers />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
