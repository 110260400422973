import React from 'react'
import { BiBone } from 'react-icons/bi'
import { FaGreaterThan } from 'react-icons/fa'
import { IoMdRocket } from 'react-icons/io'
import { GiHealthPotion, GiMuscleUp, GiHeartburn } from 'react-icons/gi'



function Section2() {
    return (
        <div className='pb-8 md:pb-0 p-16 w-[100%]' id='Composition'>
            <div className='font-semibold lg:text-3xl sm:text-2xl md:text-3xl flex justify-center items-center underline-offset-8 underline decoration-wavy'><h1 className='text-2xl'>Water Composition</h1></div>
            <div className='Parent_container'>
                <div className='grid_container'>
                    <button className='Icon_container'>
                        <BiBone className='Icon_style' style={{ color: 'white' }} />
                    </button>
                    <h6 className='Section2_h6'>Calcium</h6>
                    <p className='Section2_p'>240 mg/L <br /> About 99% of the calcium in our bodies is in our bones and teeth</p>
                </div>

                <div className='grid_container'>
                    <button className='Icon_container'>
                        <FaGreaterThan className='Icon_style' style={{ color: 'white' }} />
                    </button>
                    <h6 className='Section2_h6'>Nitrate</h6>
                    <p className='Section2_p'>4.4 mg/L <br />It’s great for health to drink water with levels of nitrate under 10 mg/L</p>
                </div>

                <div className='grid_container'>
                    <button className='Icon_container'>
                        <IoMdRocket className='Icon_style' style={{ color: 'white' }} />
                    </button>
                    <h6 className='Section2_h6'>Magnesium</h6>
                    <p className='Section2_p'>42 mg/L <br /> Magnesium is a nutrient that the body needs to stay healthy.</p>
                </div>

                <div className='grid_container'>
                    <button className='Icon_container'>
                        <GiHealthPotion className='Icon_style' style={{ color: 'white' }} />
                    </button>
                    <h6 className='Section2_h6'>Sulfate</h6>
                    <p className='Section2_p'>400 mg/L <br /> Sulfate is among the most important macronutrients in cells</p>
                </div>

                <div className='grid_container'>
                    <button className='Icon_container'>
                        <GiMuscleUp className='Icon_style' style={{ color: 'white' }} />
                    </button>
                    <p className='Section2_p'>5.2 mg/L <br /> It’s an important component for proper muscle and nerve function.</p>
                </div>

                <div className='grid_container'>
                    <button className='Icon_container'>
                        <GiHeartburn className='Icon_style' style={{ color: 'white' }} />
                    </button>
                    <h6 className='Section2_h6'>Bicarbonate</h6>
                    <p className='Section2_p'>384 mg/L <br /> Bicarbonate is an antacid used to relieve heartburn and acid indigestion.</p>
                </div>
            </div>
        </div>
    )
}

export default Section2

