import React from 'react'

function Team() {
  return (
    <section className="py-6 bg-[#ffffff] dark:text-gray-100 mt-12" id='Team'>
	<div className="container p-4 mx-auto space-y-16 sm:p-10">
		<div className="space-y-4">
			<h3 className="text-2xl font-bold leading-none sm:text-5xl text-black">Meet our team</h3>
			<p className="max-w-2xl dark:text-gray-700">At a assumenda quas cum earum ut itaque commodi saepe rem aspernatur quam natus quis nihil quod, hic explicabo doloribus magnam neque, exercitationem eius sunt!</p>
		</div>
		<div className="grid w-full grid-cols-1 gap-6 md:grid-cols-2">
			<div className="flex space-x-6">
				<img alt="" className="flex-1 flex-shrink-0 object-cover h-56 mb-4 bg-center rounded-sm dark:bg-gray-500" src="https://source.unsplash.com/240x320/?portrait?0" />
				<div className="flex flex-col">
					<h4 className="text-xl text-gray-600 font-semibold">Leroy Jenkins</h4>
					<p className="text-sm dark:text-gray-400">Web developer</p>
					{/* <div className="flex mt-2 space-x-2">
						<a rel="noopener noreferrer" href="#" title="Twitter" className="dark:text-gray-400">
						</a>
						<a rel="noopener noreferrer" href="#" title="LinkedIn" className="dark:text-gray-400">
						</a>
						<a rel="noopener noreferrer" href="#" title="GitHub" className="dark:text-gray-400">
						</a>
					</div> */}
				</div>
			</div>
			<div className="flex space-x-6">
				<img alt="" className="flex-1 flex-shrink-0 object-cover h-56 mb-4 bg-center rounded-sm dark:bg-gray-500" src="https://source.unsplash.com/240x320/?portrait?1" />
				<div className="flex flex-col">
					<h4 className="text-xl text-gray-600 font-semibold">Leroy Jenkins</h4>
					<p className="text-sm dark:text-gray-400">Web developer</p>
					{/* <div className="flex mt-2 space-x-2">
						<a rel="noopener noreferrer" href="#" title="Twitter" className="dark:text-gray-400">
						</a>
						<a rel="noopener noreferrer" href="#" title="LinkedIn" className="dark:text-gray-400">
						</a>
						<a rel="noopener noreferrer" href="#" title="GitHub" className="dark:text-gray-400">
						</a>
					</div> */}
				</div>
			</div>
			<div className="flex space-x-6">
				<img alt="" className="flex-1 flex-shrink-0 object-cover h-56 mb-4 bg-center rounded-sm dark:bg-gray-500" src="https://source.unsplash.com/240x320/?portrait?2" />
				<div className="flex flex-col">
					<h4 className="text-xl text-gray-600 font-semibold">Leroy Jenkins</h4>
					<p className="text-sm dark:text-gray-400">Web developer</p>
					{/* <div className="flex mt-2 space-x-2">
						<a rel="noopener noreferrer" href="#" title="Twitter" className="dark:text-gray-400">
						</a>
						<a rel="noopener noreferrer" href="#" title="LinkedIn" className="dark:text-gray-400">
						</a>
						<a rel="noopener noreferrer" href="#" title="GitHub" className="dark:text-gray-400">
						</a>
					</div> */}
				</div>
			</div>
			<div className="flex space-x-6">
				<img alt="" className="flex-1 flex-shrink-0 object-cover h-56 mb-4 bg-center rounded-sm dark:bg-gray-500" src="https://source.unsplash.com/240x320/?portrait?3" />
				<div className="flex flex-col">
					<h4 className="text-xl text-gray-600 font-semibold">Leroy Jenkins</h4>
					<p className="text-sm dark:text-gray-400">Web developer</p>
					{/* <div className="flex mt-2 space-x-2">
						<a rel="noopener noreferrer" href="#" title="Twitter" className="dark:text-gray-400">
						</a>
						<a rel="noopener noreferrer" href="#" title="LinkedIn" className="dark:text-gray-400">
						</a>
						<a rel="noopener noreferrer" href="#" title="GitHub" className="dark:text-gray-400">
						</a>
					</div> */}
				</div>
			</div>
			<div className="flex space-x-6">
				<img alt="" className="flex-1 flex-shrink-0 object-cover h-56 mb-4 bg-center rounded-sm dark:bg-gray-500" src="https://source.unsplash.com/240x320/?portrait?4" />
				<div className="flex flex-col">
					<h4 className="text-xl text-gray-600 font-semibold">Leroy Jenkins</h4>
					<p className="text-sm dark:text-gray-400">Web developer</p>
					{/* <div className="flex mt-2 space-x-2">
						<a rel="noopener noreferrer" href="#" title="Twitter" className="dark:text-gray-400">
						</a>
						<a rel="noopener noreferrer" href="#" title="LinkedIn" className="dark:text-gray-400">
						</a>
						<a rel="noopener noreferrer" href="#" title="GitHub" className="dark:text-gray-400">
						</a>
					</div> */}
				</div>
			</div>
			<div className="flex space-x-6">
				<img alt="" className="flex-1 flex-shrink-0 object-cover h-56 mb-4 bg-center rounded-sm dark:bg-gray-500" src="https://source.unsplash.com/240x320/?portrait?5" />
				<div className="flex flex-col">
					<h4 className="text-xl text-gray-600 font-semibold">Leroy Jenkins</h4>
					<p className="text-sm dark:text-gray-400">Web developer</p>
					{/* <div className="flex mt-2 space-x-2">
						<a rel="noopener noreferrer" href="#" title="Twitter" className="dark:text-gray-400">
						</a>
						<a rel="noopener noreferrer" href="#" title="LinkedIn" className="dark:text-gray-400">
						</a>
						<a rel="noopener noreferrer" href="#" title="GitHub" className="dark:text-gray-400">
						</a>
					</div> */}
				</div>
			</div>
		</div>
	</div>
</section>
  )
}

export default Team
