const jobPositions = {
    "HRD and Administrative Assistant Manager": {
        education: "BA/MBA in Human Resource Management or Public Administration",
        experience: "6 or 4 years of relevant work experience, out of which 3 or 2 years in supervisory position preferably in production industries",
        numberOfPositions: 1
    },
    "General Service Head": {
        education: "BSc in Personnel Management / Public administration",
        experience: "2 years of relevant work experience",
        numberOfPositions: 1
    },
    "Executive Secretary": {
        education: "Degree/Diploma in secretarial science and office management or related field",
        experience: "2 or 4 years of relevant work experience",
        numberOfPositions: 1
    },
    "Sales & Marketing Manager": {
        education: "BSc or MSc. In Marketing management, Economics, Business Administration or Industrial Engineering",
        experience: "6 or 4 years of relevant work experience, out of which 3 years in supervisory position preferably in Bottling or Food Processing Industries",
        numberOfPositions: 1
    },
    "Sales Supervisor": {
        education: "BA/Diploma in marketing management, Salesmanship, or related field",
        experience: "2 or 4 years of relevant work experience in retailing business",
        numberOfPositions: 2
    },
    "Operations Planning and IT Service Head": {
        education: "BSc in Industrial Engineering or related field",
        experience: "4 years of relevant experience, out of which 3 or 2 years in supervisory position preferably in production industries",
        numberOfPositions: 1
    },
    "Finance Manager": {
        education: "BA / MBA in Accounting / Finance or related field",
        experience: "6 or 4 years of relevant work experience, out of which 3 or 2 years in supervisory position preferably in production Industries",
        numberOfPositions: 1
    },
    "General Accountant": {
        education: "BA in Accounting or related field",
        experience: "3 years of relevant work experience preferably in production industries",
        numberOfPositions: 1
    },
    "Cost Accountant": {
        education: "BA in Accounting or related field",
        experience: "3 years of relevant work experience preferably in production industries",
        numberOfPositions: 1
    },
    "Junior Accountant or Casher": {
        education: "BA / Diploma in Accounting or related field",
        experience: "1 or 3 years of related work experience",
        numberOfPositions: 2
    },
    "Internal Auditor": {
        education: "BA/MBA Finance, Certified internal auditor or related field",
        experience: "Minimum of 6 or 4 years of internal control experience in production business",
        numberOfPositions: 1
    },
    "Production Supervisor": {
        education: "BSc. In Chemical / Industrial / Mechanical Engineering or related field",
        experience: "5 Years relevant work experience out of which 3 years in supervisory position preferably in bottling or food processing industries",
        numberOfPositions: 1
    },
    "Production Shift Leader": {
        education: "BSc. In Chemical / Industrial / Mechanical Engineering or related field",
        experience: "3 Years relevant work experience out of which 1 years in supervisory position preferably in Bottling or food processing industries",
        numberOfPositions: 3
    },
    "Production Clerk": {
        education: "10+3 in Accounting",
        experience: "Zero experience",
        numberOfPositions: 1
    },
    "Bottling Line Operator": {
        education: "10+3 in General mechanics or Industrial electricity",
        experience: "Zero experience",
        numberOfPositions: 20
    },
    "Product Quality Inspection Personnel": {
        education: "10th Complete (females)",
        experience: "Zero experience",
        numberOfPositions: 10
    },
    "Helper": {
        education: "10th complete",
        experience: "Zero experience",
        numberOfPositions: 8
    },
    "Technique / Maintenance Division Head": {
        education: "BSc. In Mechanical / Electrical Engineering or Advanced diploma in Industrial electricity or related field",
        experience: "5 / 7 Years of relevant work experience out of which 3 / 4 years in supervisory position preferably in electromechanical maintenance areas in a bottling or food processing industries",
        numberOfPositions: 1
    },
    "Senior Electrician": {
        education: "Advanced diploma / Diploma in Electricity or Industrial Electricity or Electronics or related field",
        experience: "5 Years of relevant work experience out of which 3 years in supervisory position preferably in electromechanical maintenance areas",
        numberOfPositions: 1
    },
    "Electrician": {
        education: "10+3 in Industrial Electricity or related field",
        experience: "4 years of relevant work experience",
        numberOfPositions: 1
    },
    "Senior Mechanic": {
        education: "Advanced diploma / Diploma in General Mechanics or related field",
        experience: "5 Years of relevant work experience out of which 3 years in supervisory position preferably in electromechanical maintenance areas",
        numberOfPositions: 1
    },
    "Mechanic": {
        education: "10+3 in General mechanics or related field",
        experience: "4 years of relevant work experience",
        numberOfPositions: 2
    },
    "Electromechanical Technician": {
        education: "10+3 in General mechanics or Industrial Electricity or related field",
        experience: "3 years of relevant work experience in electromechanical maintenance areas",
        numberOfPositions: 3
    },
    "Tool Store Keeper": {
        education: "10+3 in General Mechanics or related field",
        experience: "1 year relevant work experience",
        numberOfPositions: 1
    },
    "Quality Control and FSMS Monitoring Head": {
        education: "BSc or MSc. Food Engineering / Industrial Chemistry / Applied Chemistry or related field",
        experience: "3 or 5 Years of relevant work experience out of which 2 or 3 years at supervisory position preferably in bottling or food processing industries",
        numberOfPositions: 1
    },
    "Physicochemical Chemist": {
        education: "BSc in Food Engineering / Industrial Chemistry / Applied Chemistry or related field",
        experience: "2 Years of Laboratory work experience preferably in bottling or food processing industries",
        numberOfPositions: 2
    },
    "Micro Biologist": {
        education: "BSc. In Applied Biology / Food Science or related field",
        experience: "2 Years of Laboratory work experience preferably in bottling or food processing industries",
        numberOfPositions: 1
    },
    "Sanitation Engineer": {
        education: "BSc in Sanitation Engineering / Environmental Science or related field",
        experience: "2 years of relevant work experience preferably in bottling or food processing industries",
        numberOfPositions: 1
    },
    "Supplies Manager": {
        education: "BA/MBA in Supplies Management or BSc/MSc in Industrial Engineering or related field",
        experience: "6 or 4 years of relevant work experience out of which 3 or 2 years in supervisory position preferably in production Industries",
        numberOfPositions: 1
    },
    "Store Keeper": {
        education: "10+2 or 10+3 in Accounting or related field",
        experience: "2 or 1 years of relevant work experience",
        numberOfPositions: 5
    },
    "Forklift Operator": {
        education: "10+2 in General mechanics or related field",
        experience: "1 year of relevant work experience or a driving license for a vehicle",
        numberOfPositions: 2
    }
};

export default jobPositions;
