import React from 'react'
import Header from './Header'
import logo from "../assets/logoblack.png"
import { BiTime, BiTimeFive } from 'react-icons/bi'
import { FaGraduationCap, FaLocationArrow, FaRegMoneyBillAlt } from 'react-icons/fa'
import { MdLocationOn, MdWork } from 'react-icons/md'
import { BsPeopleFill } from 'react-icons/bs'
import jobPositions from "./jobPositions"

const Careers = () => {
    const openEmailClient = (e, positionName) => {

        // Replace these variables with your desired recipient email, subject, and body text
        const recipientEmail = 'info@bbkethiopia.com';
        const subject = `Job Application (${positionName})`;

        const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}`;

        // Open the user's default email client with the mailto link
        window.location.href = mailtoLink;
    };
    return (

        <div id='Careers'>
            <Header />

            <div class="job-item p-4 mb-4">

                {/* partiton one */}
                {Object.keys(jobPositions).map((positionName, index) => (
                    <div key={index} className="grid grid-cols-12 gap-4 mt-32 shadow-sm p-5">
                        <div className="col-span-12 md:col-span-8 flex items-center">
                            {/* Replace `logo` with your logo source */}
                            <img className="flex-shrink-0 w-20 h-20 border rounded object-contain" src={logo} alt="" />
                            <div className="ml-8 flex flex-col">
                                <h5 className="mb-3 text-xl font-semibold">{positionName}</h5>
                                <div className='flex space-x-5'>
                                    <span class="truncate mb-1 flex items-center gap-x-1">
                                        <i class="fas fa-map-marker-alt text-primary mr-2"></i>
                                        <MdLocationOn className='text-[#54B365] text-xl' />
                                        WondoGenet
                                    </span>
                                    <span class="truncate mb-1 flex items-center">
                                        <BiTime className='text-[#54B365] text-xl' />
                                        <i class="far fa-clock text-primary mr-2"></i>
                                        Full Time
                                    </span>
                                    <span class="truncate flex items-center gap-x-1">
                                        <i class="far fa-money-bill-alt text-primary mr-2"></i>
                                        <FaRegMoneyBillAlt className='text-[#54B365] text-xl' />
                                        Negotiable
                                    </span>
                                </div>
                                {/* Replace static data with values from jobPositions */}
                                <div className='flex space-x-5'>
                                    <span className="truncate mb-1 flex items-center gap-x-1">
                                        <i className="fas fa-map-marker-alt text-primary mr-2"></i>
                                        {/* Use jobPositions[positionName].location here */}
                                        {jobPositions[positionName].location}
                                    </span>
                                    <span className="truncate mb-1 flex items-center">
                                        <i className="far fa-clock text-primary mr-2"></i>
                                        {/* Use jobPositions[positionName].employmentType here */}
                                        {jobPositions[positionName].employmentType}
                                    </span>
                                    <span className="truncate flex items-center gap-x-1">
                                        <i className="far fa-money-bill-alt text-primary mr-2"></i>
                                        {/* Use jobPositions[positionName].salary here */}
                                        {jobPositions[positionName].salary}
                                    </span>
                                </div>
                                <div className='mt-10'>
                                    <div className='flex gap-x-2 items-center '>
                                        <FaGraduationCap className='text-xl text-[#54B365] ' />
                                        {/* Use jobPositions[positionName].education here */}
                                        <span className='text-sm'>{jobPositions[positionName].education}</span>
                                    </div>
                                    <div className='flex gap-x-2 items-center'>
                                        <MdWork className='text-xl text-[#54B365]' />
                                        {/* Use jobPositions[positionName].experience here */}
                                        <span className='text-sm'>{jobPositions[positionName].experience}</span>
                                    </div>
                                    <div className='flex gap-x-2  items-center'>
                                        <BsPeopleFill className='text-xl text-[#54B365]' />
                                        {/* Use jobPositions[positionName].numberOfPositions here */}
                                        <span className='text-sm'>{jobPositions[positionName].numberOfPositions} Person(s)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-4 flex flex-col items-start md:items-end justify-center">
                            <div className="flex mb-3 bg-[#54B365] p-3 text-white hover:bg-green-500 " onClick={(e) => { e.preventDefault(); openEmailClient(e, positionName); }}>
                                <a className="btn btn-light btn-square me-3" href=""><i className="far fa-heart text-primary"></i></a>
                                <a className="btn btn-primary font-semibold" href="">Apply Now</a>
                            </div>
                            <small className="truncate flex items-center">
                                <i className="far fa-calendar-alt text-primary mr-2"></i>
                                Date: October 1, 2023
                            </small>
                        </div>
                    </div>
                ))}


            </div>
        </div>

    )
}

export default Careers
