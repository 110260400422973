import React from 'react'
import Header from '../Components/Header'
import Section1 from '../Components/Section1'
import Section2 from '../Components/Section2'
import Products from '../Components/Products'
import Contact from '../Components/Contact'
import Footer from '../Components/Footer'
import Team from '../Components/Team'
import { Helmet } from 'react-helmet-async'

const HomePage = () => {
    return (
        <div>
            <Helmet>
                <title>Wendo Water</title>
                <meta name='description' content='Wendo water, a subsidiary of BBK Ethiopia is a leading bottled water company located in Wondo Genet, Ethiopia. We provide high-quality purified water to ensure the hydration and wellness of our customers.' />
                <link rel='canonical' href='/' />
                <meta name='keywords' content='Bottled Water, Wondo Water, Wendo Water, BBK Ethiopia, Spring Water,
            Hawassa, Wondo, Purified Water, Water, Ethiopia'
                />
            </Helmet>
            <Header />
            <Section1 />
            <Section2 />
            {/* <Products /> */}
            {/* <Team /> */}
            <Contact />
            <Footer />
        </div>
    )
}

export default HomePage
