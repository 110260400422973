import React from 'react'
import Typewriter from "typewriter-effect"
import bg from '../assets/Mountainbg.jpg'
import bubble from '../assets/bubble.png'
import bottle from '../assets/bottle.png'
import { Helmet } from 'react-helmet-async'



const Section1 = () => {

    return (
        <div className='pb-8 md:pb-0 h-screen' id='Home'>
            <img src={bg} alt="Wendo Water" className='hero' />
            <div className='content'>
                <h1 className='Section1_h1 -mb-1'>
                    <Typewriter
                        options={{
                            autoStart: true,
                            loop: true,
                            delay: 60,
                            strings: [
                                "Clear Purified Spring Water",
                                "Water for the Soul",
                                "Cooling, Refreshing Effect"
                            ]
                        }}

                    />
                </h1>
                <p className='lg:w-[800px] md:w-[700px] sm:w-[400px] mt-4 font-light text-lg sm:text-md xs:text-md'>Our nutritional content is consistent with world-leading water manufacturers and is in line with both the World Health Organisation standard and our consumers daily recommended intake.</p>
                <div className='flex flex-col xs:flex-row sm:flex-row md:flex-row lg:flex-row md:items-start sm:items-center xs:items-stretch gap-8  mr-10'>
                    <a href='#Composition'>
                        <button className='tour_button'>Take a tour</button>
                    </a>
                    <a href='#Team'>
                        <button className='bg-[#50875C] mt-4 pt-4 pb-3 pl-10 pr-10 rounded-lg hover:text-white hover:bg-black'>
                            Team
                        </button>
                    </a>
                </div>

            </div>
            <div className='flex-grow absolute top-0 right-20 w-[30%] h-[100%] items-center justify-center'>
                <img src={bottle} alt="bottle" className="object-contain w-[90%] h-auto lg:visible md:invisible sm:invisible xs:invisible"></img>
            </div>


            <div className='bubbles'>
                <img src={bubble} alt="background_image" className='bubble' />
                <img src={bubble} alt="background_image" className='bubble' />
                <img src={bubble} alt="background_image" className='bubble' />
                <img src={bubble} alt="background_image" className='bubble' />
                <img src={bubble} alt="background_image" className='bubble' />
                <img src={bubble} alt="background_image" className='bubble' />
            </div>
        </div>
    )
}

export default Section1
