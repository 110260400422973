import React, { useRef, useState } from "react"
import logo from '../assets/logoblack.png'
import { Transition } from "@headlessui/react";



function Header({ ref1 }) {

    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef()

    return (
        <div>
            <div className=" bg-white fixed w-[100%] z-50 shadow-md">
                <nav>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex items-center justify-between h-16">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <img
                                        className="h-32 w-32 object-contain"
                                        src={logo}
                                        alt="Wendo Logo"
                                    />
                                </div>
                                <div className="hidden md:block">
                                    <div className="ml-10 flex  space-x-4">
                                        <a
                                            href="/"
                                            className=" text-black hover:text-[#43654b] hover:underline underline-offset-4 decoration-4 px-3 py-2 rounded-md text-sm font-bold"
                                        >
                                            Home
                                        </a>

                                        {/* 
                                        <a
                                            href="#About"
                                            className="text-black hover:text-[#43654b] hover:underline underline-offset-4  decoration-4 px-3 py-2 rounded-md text-sm font-bold"
                                        >
                                            About Us
                                        </a> */}

                                        <a
                                            href="#Contact"
                                            className="text-black  hover:text-[#43654b] hover:underline underline-offset-4 decoration-4 px-3 py-2 rounded-md text-sm font-bold"
                                        >
                                            Contact Us
                                        </a>

                                        {/* <a
                            href="#Products" 
                            className="text-black  hover:text-[#43654b] hover:underline  underline-offset-4 decoration-4 px-3 py-2 rounded-md text-sm font-bold"
                        >
                            Products
                        </a> */}

                                        <a
                                            href="/Careers"
                                            className="text-black  hover:text-[#43654b] hover:underline  underline-offset-4 decoration-4 px-3 py-2 rounded-md text-sm font-bold"
                                        >
                                            Careers
                                        </a>

                                        <a
                                            href="#Contact"
                                            className="text-black  hover:text-[#43654b] hover:underline underline-offset-4 decoration-4 px-3 py-2 rounded-md text-sm font-bold"
                                        >
                                            Contact Info
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="-mr-2 flex md:hidden">
                                <button
                                    onClick={() => setIsOpen(!isOpen)}
                                    type="button"
                                    className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                    aria-controls="mobile-menu"
                                    aria-expanded="false"
                                >
                                    <span className="sr-only">Open main menu</span>
                                    {!isOpen ? (
                                        <svg
                                            className="block h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M4 6h16M4 12h16M4 18h16"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            className="block h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>

                    <Transition
                        show={isOpen}
                        enter="transition ease-out duration-100 transform"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-75 transform"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >

                        {(
                            <div className="md:hidden text-center z-50" id="mobile-menu">
                                <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-[#43654b]">
                                    <a
                                        href="#Home"
                                        className="hover:bg-white text-white hover:text-black  block px-3 py-2 rounded-md text-base font-bold"
                                    >
                                        Home
                                    </a>
                                    {/* 
                                    <a
                                        href="#About"
                                        className="text-white hover:bg-white hover:text-black block px-3 py-2 rounded-md text-base font-bold"
                                    >
                                        About Us
                                    </a> */}

                                    <a
                                        href="#Contact"
                                        className="text-white hover:bg-white hover:text-black  block px-3 py-2 rounded-md text-base font-bold"
                                    >
                                        Contact Us
                                    </a>

                                    <a
                                        href="/Careers"
                                        className="text-white hover:bg-white hover:text-black  block px-3 py-2 rounded-md text-base font-bold"
                                    >
                                        Careers
                                    </a>

                                    {/* <a
                                        href="#Products"
                                        className="text-white hover:bg-white hover:text-black   block px-3 py-2 rounded-md text-base font-bold"
                                    >
                                        Products
                                    </a> */}

                                    <a
                                        href="#Contact"
                                        className="text-white hover:bg-white hover:text-black   block px-3 py-2 rounded-md text-base font-bold"
                                    >
                                        Contact Info
                                    </a>
                                </div>
                            </div>
                        )}
                    </Transition>
                </nav>
            </div>
        </div>
    )
}

export default Header
