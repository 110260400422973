import React from 'react'
import { BsFacebook, BsInstagram, BsTelegram } from 'react-icons/bs'
import logo from '../assets/logoblack.png'
import { MdLocationPin } from 'react-icons/md'

function Footer() {
	return (
		<footer className="py-6 dark:bg-[#43654b] bg-[#43654b] text-white dark:text-gray-50 font-aquire">
			<div className="container px-6 mx-auto space-y-6 divide-y divide-gray-400 md:space-y-12 divide-opacity-50">
				<div className="lg:grid md:grid sm:grid grid-cols-12">
					<div className="pb-5 col-span-full md:pb-0 md:col-span-6">
						<a rel="noopener noreferrer" href="#Home" className="flex justify-center space-x-3 md:justify-start">
							<div>
								<img src={logo} alt='Wendo Logo' className='w-48 h-48 object-contain' />
							</div>
						</a>
					</div>
					<div className="col-span-6 text-center md:text-left md:col-span-3 mt-7">
						<p className="pb-1 text-lg font-medium">Quick Links</p>
						<ul>
							<li>
								<a rel="noopener noreferrer" href="#Home" className="hover:text-black">Home</a>
							</li>
							<li>
								<a rel="noopener noreferrer" href="#About" className="hover:text-black">About Us</a>
							</li>
							<li>
								<a rel="noopener noreferrer" href="#Contact" className="hover:text-black">Contact Us</a>
							</li>
							{/* <li>
								<a rel="noopener noreferrer" href="#Products" className="hover:text-black">Products</a>
							</li> */}
							<li>
								<a rel="noopener noreferrer" href="#Careers" className="hover:text-black">Careers</a>
							</li>
						</ul>
					</div>
					<div className="col-span-6 text-center md:text-left md:col-span-3 p-2 sm:p-0 md:p-0 flex lg:gap-4 md:gap-4 sm:gap-4 xs:gap-0  md:mt-0 sm:mt-0 xs:mt-8 lg:mt-10">
						<div className='bg-white rounded-full w-10 h-10 items-center text-center p-1 mb-2 mt-7'>
							<MdLocationPin className='text-black text-3xl' />
						</div>

						<p className="pb-1 font-normal text-sm">
							Office Bisrate Gabreal, Water Building
							<br></br>Factory location -
							Outskirts of Addis Ababa Wendo
						</p>

					</div>
				</div>
				<div className="grid justify-center pt-6 lg:justify-between">
					<div className="flex flex-col self-center text-sm text-center md:block lg:col-start-1 md:space-x-6">
						<span>©2022 All rights reserved</span>
						<a rel="noopener noreferrer" href="#Privacy">
							<span>Privacy policy</span>
						</a>
						<a rel="noopener noreferrer" href="#Terms of Service">
							<span>Terms of service</span>
						</a>
					</div>
					<div className="flex justify-center pt-4 space-x-4 lg:pt-0 lg:col-end-13">
						<a rel="noopener noreferrer" href="#Telegram" title="Telegram" className="flex items-center justify-center w-10 h-10 rounded-full bg-white  dark:text-gray-900">
							<BsTelegram className='text-xl' />
						</a>
						<a rel="noopener noreferrer" href="#Twitter" title="Instagram" className="flex items-center justify-center w-10 h-10 rounded-full bg-white dark:text-gray-900">
							<BsInstagram className='text-xl' />
						</a>
						<a rel="noopener noreferrer" href="#Facebook" title="Facebook" className="flex items-center justify-center w-10 h-10 rounded-full bg-white dark:text-gray-900">
							<BsFacebook className='text-xl' />
						</a>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
